<template>
  <div class="ovy-a" style="min-width: 100%">
    <div class="addlist">
      <el-form
        :model="formData"
        ref="formData"
        :rules="rules"
        label-width="100px"
        :disabled="formDisabled"
        class="Score-formData"
      >
        <el-form-item label="问题:" prop="explainQuestion">
          <el-input
            v-model="formData.explainQuestion"
            placeholder="请输入问题"
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="问题分类"
          prop="explainType"
          class="searchboxItem ci-full"
        >
          <el-select
            v-model="formData.explainType"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option
              v-for="item in explainTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="解决方法:" prop="explainAnswer">
          <!-- <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入解决方法"
                v-model="formData.explainAnswer"
              >
              </el-input> -->
          <div
            ref="editor"
            style="width: 100%; position: relative; z-index: 2"
          />
          <!-- <div ref="editor" class="editor" style="width: 100%" /> -->
        </el-form-item>

        <el-form-item label="是否发布:" prop="publish">
          <el-switch v-model="formData.publish" active-color="#13ce66">
          </el-switch>
          <span style="vertical-align: middle; margin-left: 4px">{{
            formData.publish ? "是" : "否"
          }}</span>
        </el-form-item>
      </el-form>
      <div class="btn-box" style="margin-left: 200px">
        <el-button @click="doClose" class="bgc-bv">取 消</el-button>
        <el-button class="bgc-bv" @click="onAddSave('formData')">
          确 定
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";

let editor = {};
export default {
  props: {
    explainId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      explainTypeList: [], // 分类选项
      formDisabled: false, // 表单禁用状态

      // 表单数据
      formData: {
        explainQuestion: "", // 问题
        explainType: "", // 分类
        explainAnswer: "", // 解决方法
        publish: false, // 是否发布
      },
      // 表单验证
      rules: {
        explainQuestion: [
          { required: true, trigger: "blur", message: "请输入问题" },
        ],
        explainType: [
          { required: true, trigger: "blur", message: "请选择问题分类" },
        ],
        explainAnswer: [
          { required: true, trigger: "blur", message: "请输入解决方法" },
        ],
        publish: [
          { required: true, trigger: "blur", message: "请选择是否发布" },
        ],
      },
    };
  },
  beforeDestroy() {
    editor = {};
  },
  mounted() {
    this.getClassification();
    this.editorInit();
    if (this.explainId) {
      this.info();
    }
  },
  methods: {
    // 关闭
    doClose() {
      this.formData = [];
      this.explainId = "";
      this.$emit("close");
    },
    // 提交数据
    onAddSave(formName) {
      this.$refs[formName].validate((valid) => {
        console.log("valid", valid);
        if (valid) {
          let data = {};
          let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
          data = {
            explainQuestion: this.formData.explainQuestion, // 问题
            explainType: this.formData.explainType, // 分类
            explainAnswer: this.formData.explainAnswer, // 解决方法
            publish: this.formData.publish, // 是否发布
          };
          // 根据 explainId 判断新增修改
          if (this.explainId) {
            data.explainId = this.explainId; // 主键id
          }
          this.$post("/sys/feedback/explain/saveOrUpdate", data).then((res) => {
            console.log("res: ", res);
            this.$message({
              type: "success",
              message: this.explainId ? "修改成功" : "新增成功",
            });
            this.$emit("close");
          });
        }
      });
    },
    // 回显详情
    info() {
      this.$post("/sys/feedback/explain/callback", {
        explainId: this.explainId,
      }).then((res) => {
        console.log("res: ", res);
        if (res.status == "0") {
          this.formData = res.data;
          editor.txt.html(res.data.explainAnswer);
        }
      });
    },
    // 富文本
    editorInit() {
      console.log(this.$refs.editor);
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = false;
      editor.config.customUploadImg = (files, insert) => {
        let extension = files[0].name.substring(
          files[0].name.lastIndexOf(".") + 1,
          files[0].name.length
        );
        let formData = new FormData();
        formData.append("file ", files[0]);
        formData.append("fileType ", extension);
        formData.append("folder ", "LECTURER");
        formData.append("isPublic ", true);
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            insert(result.data.fileURL);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      };
      editor.config.onchange = (html) => {
        this.formData.explainAnswer = html;
      };
      editor.config.pasteTextHandle = function (content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },
    // 获取分类数据
    getClassification() {
      let list = this.$setDictionary("FEEDBACKEXPLAINTYPE", "list");
      for (const key in list) {
        const element = list[key];
        this.explainTypeList.push({
          value: key,
          label: element,
        });
      }
    },
  },
};
</script>

<style>
</style>